// App.js
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { ROUTES } from './constants/constants';
import ReusableAppBar from './components/ui/appBar';
import Footer from './components/ui/footer';
import { css } from '@emotion/react'; // Import CSS from react-spinners
import { ClipLoader, BarLoader, PulseLoader } from 'react-spinners'; // Import ClipLoader from react-spinners
import { Helmet } from 'react-helmet';

// Lazy-loaded components
const Home = lazy(() => import('./pages/home'));
const ProfilePage = lazy(() => import('./pages/profile'));
const SignInSignUp = lazy(() => import('./pages/signInSignUp'));
const VisaSignUpForm = lazy(() => import('./pages/visaSignUpForm'));
const About = lazy(() => import('./pages/about'));
const FAQ = lazy(() => import('./pages/faqPage'));
const AdminPage = lazy(() => import('./pages/admin'));
const SignIn = lazy(() => import('./components/auth/signIn'));
const TestPage = lazy(() => import('./pages/test'));
const PaymentForm = lazy(() => import('./pages/payment'));
const Requirements = lazy(() => import('./pages/requirements'));
const Documents = lazy(() => import('./pages/drfv'));
const WhoNeedsAVisa = lazy(() => import('./pages/wnav'));
const ReportPage = lazy(() => import('./pages/reportPage'));
const VisaSignUpEditForm = lazy(() => import('./pages/visaSignUpEdit'));

function App() {
  // Add a function to check if the user is authenticated
  const isAuthenticated = () => {
    // Check if the user is authenticated (e.g., by checking the presence of adminData in localStorage)
    return localStorage.getItem('adminData') !== null;
  };

  const PrivateRoute = ({ element }) => {
    // If the user is authenticated, render the provided element (AdminPage), otherwise, redirect to the sign-in page
    return isAuthenticated() ? element : <Navigate to="/signin" />;
  };


  const override = css`
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
  `;

  const LocationAwareHelmet = () => {
    const location = useLocation();

    useEffect(() => {
      const title = getPageTitle(location.pathname);
      document.title = title;
    }, [location.pathname]);

    return null;
  };

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/':
        return ' Bing Visa';
      case '/kenya':
        return 'Apply for Kenya online visa or eTA - Bing Visa';
      case ROUTES.ABOUT:
        return 'About Us - Bing Visa';
      case '/requirements':
        return 'Requirements - Bing Visa';

      default:
        return 'Bing Visa';
    }
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ReusableAppBar />
          <Suspense fallback={<div style={{marginTop:"300px", marginBottom:"900px"}}><div style={{height:"150", width:"auto", margin:"30%", justifyContent:"center", textAlign:"center", alignContent:"center", backgroundColor:"white"}}><PulseLoader color="#1976D2" css={override} size={40} /></div></div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/kenya" element={<Home />} />
              <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
              <Route path={ROUTES.SIGN_UP} element={<SignInSignUp />} />
              <Route path={ROUTES.CREATE_VISA} element={<VisaSignUpForm />} />
              <Route path={ROUTES.ABOUT} element={<About />} />
              <Route path={ROUTES.FAQ} element={<FAQ />} />
              <Route path={ROUTES.ADMIN} element={<PrivateRoute element={<AdminPage />} />} />
              <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
              <Route path="/test" element={<TestPage />} />
              <Route path="/payment" element={<PaymentForm />} />
              <Route path="/requirements" element={<Requirements />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/who-needs-a-visa" element={<WhoNeedsAVisa />} />
              <Route path={`${ROUTES.REPORT}/:visaId`} element={<ReportPage />} />
              <Route path={`${ROUTES.VISA_SIGN_UP_EDIT}/edit/:id`} element={<VisaSignUpEditForm />} />
            </Routes>
          </Suspense>
          <Footer />
          <LocationAwareHelmet />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
